import { AxiosRequestConfig } from "axios";
import { API_URLS } from "constants/api-urls";
import { OrderType, StatusType, TransactionStatus } from "constants/status";
import { aggregatorApi, api, torquePrivateApi } from "utils/api";

// Fetch-Transaction types
export type Transaction = {
  orderId: number;
  orderDate: string | Date;
  orderStatus: StatusType;
  orderTotal: number;
  transactionId: number;
  productId: number;
  isRedeemed: boolean;
  name: string;
  imageUrl?: string;
};

export type TransactionDetail = {
  type: OrderType;
  orderInitiatedDate: string;
  productName: string;
  paymentMode: string;
  amount: number;
  units: number;
  navPrice: number;
  orderStatus: number;
  paymentStatus: number;
  productId: number;
  folioNumber: string;
  navDateAllotmentDate: string;
  statusTimeStamps: StatusTimeStamp[];
};

export type TorqueTransaction = {
  amount: number;
  countryId: number;
  id: number;
  orderDate: string; // You can use Date type if you are converting it to Date object elsewhere.
  reconciled: boolean;
  status: number;
  toFrom: string;
};
export type AggregatedTransaction = {
  id: number;
  status: number;
  toFrom: string;
  countryId: number | null;
  amount: number;
  orderDate: string;
  type: string;
  isActionRequired: number;
  convertedAmount: number;

};

export interface StatusTimeStamp {
  event: number;
  timestamp: string;
}

export interface OrderDetail {
  orderDate: string;
  amount: number;
  exchangeRate: number | null;
  status: number;
  statusTimestamps: StatusTimeStamp[];
  toFrom: string;
  countryId: number;
  reconciled: boolean | null;
  id: string;
  expectedArrivalDate: Date;
  invoiceFileUrl: string;
  firaFileUrl: string;
  amountBreakup: ApprovalRateResponse | null;

}

export interface DiscountResponse {
  depositDate: Date | null;
  discount: number;
  isActive: boolean;
  name: string;
  status: string | null;
}


export interface ApprovalRateResponse {
  amountToInr: number;
  fx: number;
  grossAmount: number;
  markup: string;
  midMarketFx: string;      // Added
  platformFee: number;      // Added
  receivableAmount: number; // Added
  savedAmount: string;
  validFrom: string;        // Changed from Date to string
  validTill: string;        // Changed from Date to string
  activeDeals:string[];
  markupPercentage:string;
  fxFixedDate:string;
  discount?:DiscountResponse;
  // Removed: netAmount, ourFee, conversionRate
}

export type FetchTreasuryTransactionRequest = {
  page?: number;
  limit?: number;
  status?: string;
};
export type FetchTreasuryTransactionResponse = {
  httpStatus: "OK";
  data: Transaction[];
};
export type FetchTransactionDetailResponse = {
  httpStatus: "OK";
  data: OrderDetail;
};
export type FetchAggregatedTransactionResponse = {
  httpStatus: "OK";
  data: AggregatedTransaction[];
};
export type FetchTorqueTransactionResponse = {
  httpStatus: "OK";
  data: {
    orderList: TorqueTransaction[];
    count: number;
  };
};
export type FetchTransactionHistoryDetailsResponse = {
  httpStatus: "OK";
  data: TransactionDetail
}

export type FetchApprovalRateResponse = {
  httpStatus: "OK";
  data: ApprovalRateResponse;
};

const TransactionsService = {
  getTreasuryTransactions: async function (
    data: FetchTreasuryTransactionRequest
  ) {
    let requestURL = `${API_URLS.transactions.treasury}?page=${data?.page}&size=${data?.limit}`;

    if (data?.status) {
      requestURL += `&status=${data?.status}`;
    }

    const res = await api.get<FetchTreasuryTransactionResponse>(requestURL);
    return res.data;
  },
  getAggregatedTransactions: async function (params?: AxiosRequestConfig) {
    const res = await aggregatorApi.get<FetchAggregatedTransactionResponse>(
      API_URLS.transactions.aggregatedTransactions,
      params
    );
    return res.data;
  },
  getTransactionDetailsByOrderId: async function (id: string) {
    let requestURL = `${API_URLS.transactions.treasury}/${id}`;
    const res = await api.get<FetchTransactionHistoryDetailsResponse>(requestURL);
    return res.data;
  },

  getGlobalPaymentsTransactions: async function (params?: AxiosRequestConfig) {
    const res = await torquePrivateApi.get<FetchTorqueTransactionResponse>(
      `${API_URLS.transactions.torque}`,
      params
    );
    return res.data;
  },
  getGlobalPaymentsTransactionsById: async function (id: number) {
    const res = await torquePrivateApi.get<FetchTransactionDetailResponse>(
      `${API_URLS.transactions.torque}/${id}`
    );
    return res.data;
  },
  DoReconcile: function (id: string) {
    return torquePrivateApi.post(`${API_URLS.transactions.reconcile}/${id}`);
  },
  GetApprovalRate: async function (
    orderId: string,
    params?: AxiosRequestConfig
  ) {
    const res = await torquePrivateApi.get<FetchApprovalRateResponse>(
      `${API_URLS.transactions.approval}/${orderId}`,
      params
    );
    return res.data;
  },
};

export default TransactionsService;
