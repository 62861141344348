import { ReferIcon } from "assets";
import React from "react";
const ReferAndEarn = ({
  onClick
}: {
  onClick: () => void;
}) => {
  return <button className="flex items-center gap-3 px-4 py-2 rounded-lg  bg-gradient-to-r from-[#FBBF25] to-[#D39101] hover:bg-amber-200/10 hover:scale-105 active:scale-95 transition-all" onClick={onClick} data-sentry-component="ReferAndEarn" data-sentry-source-file="ReferAndEarn.tsx">
      <div className="w-6 h-6 text-amber-500">
        <img src={ReferIcon} alt="refer" />
      </div>

      <span className="text-sm text-white font-medium">Refer & Earn</span>
    </button>;
};
export default ReferAndEarn;