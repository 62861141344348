import axios, { AxiosRequestConfig } from "axios";
import { API_URLS } from "constants/api-urls";
import { torquePublicApi, torquePrivateApi, discountApi } from "utils/api";

export type CountriesProps = {
  id: number;
  name: string;
  currencySymbol: string;
  currencyName: string;
  dialingCode: string;
  createdAt: Date;
  flagIconUrl: string;
};

export type CountryOfSupplyProps = {
  id: number;
  countryName: string;
  supplyCountrySymbol: string;
};

export type TransactionTypeResponse = {
  createdAt: Date;
  id: number;
  purposeCodeId: number;
  type: string;
};

export type PurposeCodeResponse = {
  createdAt: Date;
  id: number;
  purposeCode: string;
};

export type ExchangeRateResponse = {
  midMarket: string;
};

export type FetchCountryResponse = {
  httpStatus: "OK";
  data: CountriesProps[];
};

export type FetchTransactionTypeResponse = {
  httpStatus: "OK";
  data: TransactionTypeResponse[];
};
export type FetchPurposeCodeResponse = {
  httpStatus: "OK";
  data: PurposeCodeResponse[];
};

export type FetchCountryOfSupplyResponse = {
  httpStatus: "OK";
  data: CountryOfSupplyProps[];
};

export const CommonService = {
  GetCountries: function () {
    return torquePrivateApi.get<FetchCountryResponse>(API_URLS.common.country, {
      headers: { "Cached-Response": true },
    });
  },
  GetTransactionType: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchTransactionTypeResponse>(
      API_URLS.common.transactionType,
      params
    );
  },
  GetPurposeCode: function (id: number, params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchPurposeCodeResponse>(
      API_URLS.common.purposeCode(id),
      params
    );
  },
  GetExchangeRate: function (id: number, params?: AxiosRequestConfig) {
    return torquePrivateApi.get<ExchangeRateResponse>(
      `${API_URLS.common.exchangeRate}/${id}`,
      params
    );
  },
  GetCountryOfSupplyList: function (params?: AxiosRequestConfig) {
    return torquePrivateApi.get<FetchCountryOfSupplyResponse>(
      API_URLS.common.countryOfSupply,
      params
    );
  },
  GetReferralCode: function (data:any,params?: AxiosRequestConfig) {
    return discountApi.post(
      API_URLS.common.referral,
      data,
      params
    );
  },

};

export default CommonService;
