import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { PATH_AUTH } from "router/path";
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import { userpool } from "userpool";

let globalUserObj = null;
// Add this new function at the top of the file

const getUser = (email, isNew = false) => {
  if (isNew) {
    globalUserObj = new CognitoUser({
      Username: email || localStorage.getItem("email"),
      Pool: userpool,
    });
  }
  if (!globalUserObj) {
    globalUserObj = userpool.getCurrentUser();
    const sessionFromStorage = localStorage.getItem("userSession");
    if (sessionFromStorage) {
      const sessionData = JSON.parse(sessionFromStorage);
      const {
        idToken: { jwtToken: idToken },
        accessToken: { jwtToken: accessToken },
        refreshToken: { token: refreshToken },
      } = sessionData;
      const IdToken = new CognitoIdToken({ IdToken: idToken });
      const AccessToken = new CognitoAccessToken({ AccessToken: accessToken });
      const RefreshToken = new CognitoRefreshToken({
        RefreshToken: refreshToken,
      });
      const session = new CognitoUserSession({
        IdToken,
        AccessToken,
        RefreshToken,
      });
      // console.log("user session", session.getAccessToken().getToken());
      globalUserObj.setSignInUserSession(session);
    }
  }
  return globalUserObj;
};

export const getEmail = () => {
  // return getUserAttribute()
  //   .then((attributes) => {
  //     const email = attributes.find(
  //       (attribute) => attribute.Name == "email",
  //     ).Value;
  //     return email;
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //   });
  const email = localStorage.getItem("email");
  if (email != null) {
    return email;
  }
  return "";
};

export const authenticate = (email, password) => {
  return new Promise((resolve, reject) => {
    const user = getUser(email, true);

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    // console.log("authDetails", authDetails);

    user?.authenticateUser(authDetails, {
      onSuccess: (res) => {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            console.log("session user", session);

            localStorage.setItem("userSession", JSON.stringify(session));
            // user.setSignInUserSession(session);
            resolve(session);
          }
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const authenticateWithGoogle = async (
  email,
  idToken,
  accessToken,
  refreshToken,
  isReferral = false,
  attributeList = []
) => {
  try {
    const userData = {
      Username: email, // The user's email or username
      Pool: userpool,
    };

    const user = new CognitoUser(userData);

    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
    });
    console.log("session in user attribute", session,isReferral,attributeList);
    // console.log("session", session);
    user.setSignInUserSession(session);
    if(isReferral){
      user.updateAttributes(attributeList, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          console.log("data in user attribute",data);
        }
      });
    }
    localStorage.setItem("userSession", JSON.stringify(session));
    return session;
  } catch (error) {
    console.error("Error in Google Sign-In:", error);
  }
};

export const exchangeGoogleAuthCodeForCognitoTokens = async (code, isReferral = false,attributeList = []) => {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT_ID;

  console.log("USER ATTRIBUTE LIST", attributeList);

  const requestBody = new URLSearchParams({
    client_id: clientId,
    code,
    redirect_uri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : process.env.REACT_APP_DOMAIN,
    grant_type: "authorization_code",
    // state: codeVerifier,
  });

  const response = await fetch(`${domain}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: requestBody,
  });
  const data = await response.json();
  console.log("data got from cognito----", data);
  const decodedToken = jwtDecode(data.id_token);
  console.log("decodedToken", decodedToken);
  const session = await authenticateWithGoogle(
    decodedToken.email,
    data.id_token,
    data.access_token,
    data.refresh_token,
    isReferral,
    attributeList
  );
  console.log("data exchange code for cognito token",session, data);
  return {
    ...session,
    email: decodedToken.email,
  };
};


export const redirectToGoogleSSO = (referralCode = "") => {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const params = new URLSearchParams({
    response_type: "code",
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri:
      process.env.NODE_ENV === "development"
        ? `http://localhost:3000`
        : `${process.env.REACT_APP_DOMAIN}`,
    identity_provider: "Google",
    state: JSON.stringify({ referralCode }),
  });
  params.append("scope", "profile openid email aws.cognito.signin.user.admin");
  return `${domain}/oauth2/authorize?${params.toString()}`;
};

export const getUserAttribute = (email) => {
  const user = getUser(email);
  return new Promise((resolve, reject) => {
    user?.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  });
};

export const verifyMFA = (mfaCode, email) => {
  return getUserAttribute(email)
    .then((attributes) => {
      const customAuthAttribute = attributes.find(
        (attribute) => attribute.Name == "custom:authChallenge"
      );
      return customAuthAttribute.Value.split(",")[0] == mfaCode;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getUserName = (email) => {
  return getUserAttribute(email)
    .then((attributes) => {
      const firstName = attributes.find(
        (attribute) => attribute.Name == "given_name"
      ).Value;
      const lastName = attributes.find(
        (attribute) => attribute.Name == "family_name"
      ).Value;
      if (firstName && lastName) return [firstName, lastName].join(" ");
      else return "";
    })
    .catch((err) => {
      console.error(err);
      if (err.message === "Access Token has expired") {
        handleLogout();
      }
    });
};

export const getUserPhoneNumber = (email) => {
  return getUserAttribute(email)
    .then((attributes) => {
      const phoneNumber = attributes.find(
        (attribute) => attribute.Name === "phone_number"
      ).Value;
      return phoneNumber;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const logout = () => {
  const user = userpool.getCurrentUser();
  user?.signOut();
  globalUserObj = null;
};

export const handleLogout = () => {
  logout();
  localStorage.clear();
  window.location = PATH_AUTH.login;
};

export const verifyOTP = (OTP, email) => {
  return new Promise((resolve, reject) => {
    const user = getUser(email, true);
    user?.confirmRegistration(OTP, true, (err) => {
      if (err) reject(err);
      else resolve();
    });
  });
};

export const resendOTP = (email) => {
  return new Promise((resolve, reject) => {
    const user = getUser(email, true);
    user?.resendConfirmationCode((err) => {
      if (err) reject(err);
      else resolve();
    });
  });
};

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    const user = getUser(email, true);
    user?.forgotPassword({
      onSuccess: (err) => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const resetPassword = (email, newPassword, verificationCode) => {
  return new Promise((resolve, reject) => {
    const user = getUser(email);
    user?.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => resolve(),
      onFailure: (err) => reject(err),
    });
  });
};
