import { Fragment, useCallback, useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightLeft, Bell, Briefcase, LayoutDashboard, LifeBuoy, MenuIcon, Settings, LogOut, X, CircleUser, LineChart, FileText, CircleHelp } from "lucide-react";
import { Logo } from "components/Logo";
import { getUserName, logout } from "services/authenticate";
import { Link } from "react-router-dom";
import { PATH_AUTH, PATH_DASHBOARD } from "router/path";
import ProtectedComponent from "components/RBAC/ProtectedComponent";
import { useUser } from "hooks/useUser";
import { useInit } from "hooks/useInit";
import MobileSidebar from "./MobileSidebar";
import SidebarItem from "./SidebarItem";
import sidebarConfig from "./navig-config";
import { Avatar } from "antd";
import { Icon } from "@iconify/react";
import { Popover, PopoverContent, PopoverTrigger } from "design-system/components/ui/popover";
import { Button } from "design-system/components/ui-v2";
import { signOutRedirect } from "utils/logout";
import ReferAndEarn from "components/ReferAndEarn";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
function matchesCurrentRoute(location: Location<any>, path?: string) {
  if (path && location?.pathname) {
    return path === location?.pathname || location?.pathname.includes(path);
  }
  return false;
}
export const Sidebar = ({
  title,
  canNavigateBack = false,
  contentClassName = "",
  children
}: {
  title?: string;
  canNavigateBack?: boolean;
  contentClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    cleanUpUser
  } = useUser();
  const {
    cleanUp
  } = useInit();
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    userDetails
  } = useUser();
  const [username, setUsername] = useState("");
  const navigateToReferral = () => {
    navigate(PATH_DASHBOARD.app.referral);
  };
  const handleNavigateRoute = useCallback((path?: string) => () => {
    if (path) {
      navigate(path);
    } else {
      logout();
      cleanUpUser();
      cleanUp();
      localStorage.clear();
      navigate(PATH_AUTH.login);
    }
  }, [navigate]);
  return <div className="lg:flex lg:h-screen xl:h-screen bg-page-background" data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.tsx">
      <MobileSidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} handleNavigateRoute={handleNavigateRoute} matchesCurrentRoute={matchesCurrentRoute} sidebarNavigationTop={sidebarConfig.top} sidebarNavigationBottom={sidebarConfig.bottom} data-sentry-element="MobileSidebar" data-sentry-source-file="Sidebar.tsx" />

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 xl:w-60 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-dark-black px-4 md:px-6 py-4">
          <div className="flex h-16 shrink-0 items-center">
            <Link to={PATH_DASHBOARD.app.dashboard} data-sentry-element="Link" data-sentry-source-file="Sidebar.tsx">
              <Logo data-sentry-element="Logo" data-sentry-source-file="Sidebar.tsx" />
            </Link>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              {/* Top Navigation */}
              <li>
                <ul className="space-y-2 md:space-y-4">
                  {sidebarConfig.top.map(item => <ProtectedComponent exact={false} requiredPermissions={item.permissions} fallback={<></>}>
                      <SidebarItem key={item.name} item={item} location={location} onNavigate={handleNavigateRoute} matchesCurrentRoute={matchesCurrentRoute} />
                    </ProtectedComponent>)}
                </ul>
              </li>

              {/* Bottom Navigation */}
              <li className="lg:hidden  mt-auto">
                <ul className="space-y-2 md:space-y-4">
                  {sidebarConfig.bottom.map(item => <ProtectedComponent exact={false} requiredPermissions={item.permissions} fallback={<></>}>
                      <SidebarItem key={item.name} item={item} location={location} onNavigate={handleNavigateRoute} matchesCurrentRoute={matchesCurrentRoute} />
                    </ProtectedComponent>)}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-1 flex-col lg:pl-60 xl:pl-60">
        {/* ... Header section ... */}
        <div className="sticky top-0 z-40 bg-page-background flex shrink-0 items-center gap-x-4 py-6 lg:py-10 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="MenuIcon" data-sentry-source-file="Sidebar.tsx" />
          </button>

          <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
          <h2 className="text-heading font-semibold lg:text-2xl tracking-wide">
            {title}
          </h2>

          <div className="flex ml-auto gap-x-4 self-stretch lg:gap-x-6">
            {window.location.pathname !== PATH_DASHBOARD.app.referral && <ReferAndEarn onClick={navigateToReferral} />}
            <div className="flex items-center gap-x-4 lg:gap-x-4">
              <Popover data-sentry-element="Popover" data-sentry-source-file="Sidebar.tsx">
                <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="Sidebar.tsx">
                  <div className="hidden lg:flex lg:items-center lg:flex-row gap-x-2">
                    <Avatar className="bg-default-accent-100 text-black " data-sentry-element="Avatar" data-sentry-source-file="Sidebar.tsx">
                      {userDetails?.accountData?.companyName?.charAt(0) || userDetails?.accountData?.firstName?.charAt(0)}
                    </Avatar>
                    <div className="flex flex-row gap-x-2 text-gray-600 hover:text-gray-950 cursor-pointer">
                      <p className="text-sm font-medium">
                        {userDetails?.accountData?.companyName || `${userDetails?.accountData?.firstName} ${userDetails?.accountData?.lastName}`}
                      </p>
                      <Icon icon="ph:caret-down" height={18} width={18} data-sentry-element="Icon" data-sentry-source-file="Sidebar.tsx" />
                    </div>
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] mr-4" data-sentry-element="PopoverContent" data-sentry-source-file="Sidebar.tsx">
                  <div className="flex flex-col space-y-4 p-2">
                    {/* Profile Section */}
                    <div className="flex flex-col items-center text-center pb-4 border-b">
                      <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center text-xl mb-2">
                        {userDetails?.accountData?.companyName?.charAt(0) || userDetails?.accountData?.firstName?.charAt(0)}
                      </div>
                      <h2 className="text-lg font-semibold">
                        {`${userDetails?.accountData?.firstName} ${userDetails?.accountData?.lastName}`}
                      </h2>
                      <p className="text-sm text-gray-600">
                        {userDetails?.accountData?.companyName || ""}
                      </p>
                    </div>

                    {/* Menu Items */}
                    <div className="space-y-3">
                      <button className="w-full flex items-center px-2 py-1.5 hover:bg-gray-100 rounded-md" onClick={() => navigate(PATH_DASHBOARD.app.profile)}>
                        <CircleUser className="w-5 h-5 mr-2" data-sentry-element="CircleUser" data-sentry-source-file="Sidebar.tsx" />
                        <span>User details</span>
                      </button>

                      <a className="w-full flex items-center px-2 py-1.5 hover:bg-gray-100 rounded-md" href="https://infinityapp.in/help-center" target="_blank" rel="noreferrer noopener">
                        <CircleHelp className="w-5 h-5 mr-2" data-sentry-element="CircleHelp" data-sentry-source-file="Sidebar.tsx" />
                        <span>Help center</span>
                      </a>

                      {/* Logout Button */}

                      <Button variant="outline" className="w-full" iconPosition="left" icon={<LogOut />} onClick={handleNavigateRoute()} data-sentry-element="Button" data-sentry-source-file="Sidebar.tsx">
                        Logout
                      </Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <main className="bg-page-background ">
          <div className={`px-4 md:px-4 lg:px-4 ${contentClassName}`}>
            {children}
          </div>
        </main>
      </div>
    </div>;
};
export default Sidebar;